<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" class="d-flex align-items-center justify-content-start">
                <h5 class="mt-2"><CIcon name='cil-list' size='xl' class="titulo-icono" />{{ $t('label.navReports') }} / PORT SUMMARY</h5>
            </CCol>
            <CCol sm="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton>
                <CButton
                    color="excel" class="" 
                    @click="onBtnExport()"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
            </CCol>
        </CRow>
      
        <CRow>
            <CCol sm="6" lg="4">
                <CInput
                    :horizontal="{ label: 'col-sm-2', input: 'col-sm-8 mx-2'}"
                    :label="$t('label.filter')+': '"
                    @input="onQuickFilterChanged"
                    required 
                    :placeholder="$t('label.enterSearch')" 
                >
                </CInput>
            </CCol>
            <CCol sm="6" lg="8">
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import { DateFormater, GenerateExcel } from '@/_helpers/funciones';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            UnitMeasureId: '',
            unitOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnDefs: [
                {
                    field: "Port",
                    headerName: "PORT",
                    lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 120,
                    cellClass: 'text-center',
                    //checkboxSelection: true, 
                },
                {
                    field: "Units",
                    headerName: "UNIT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 120,
                    cellClass: 'text-center',
                },
                {
                    field: "Teus",
                    headerName: "TEUS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 120,
                    cellClass: 'text-center',
                },
                {
                    field: "TotalWeight",
                    headerName: "TOTAL WEIGHT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'text-center',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'text-center',
                },
                {
                    field: "Reefer",
                    headerName: "REEFER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Dry",
                    headerName: "DRY",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Class",
                    headerName: "IMDG",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Full",
                    headerName: "FULL",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Empty",
                    headerName: "EMPTY",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Cont40Ft",
                    headerName: "40'",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Cont40FtWeight",
                    headerName: "40'-WEIGHT",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Cont40Ft",
                    headerName: "20'",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "Cont20FtWeight",
                    headerName: "20'-WEIGHT",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "BbUnits",
                    headerName: "BB-UNITS",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
                {
                    field: "BbWeight",
                    headerName: "BB-WEIGHT",
                    headerClass: 'center-cell',
                    sortable: true,
                    lockPosition: true,
                    width: 170,
                    cellClass: 'text-center',
                },
            ],
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onBtnPdf(){
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0){
            let columns = [];
            for (let index = 1; index < this.columnDefs.length; index++) {
                columns.push({
                    title: this.columnDefs[index].headerName,
                    dataKey: this.columnDefs[index].field
                });
            }
            const doc = new jsPDF({
                orientation: 'l',
                unit: 'pt',
                format: [300, (columns.length*100)],
                putOnlyUsedFonts:true,
                floatPrecision: 16 // or "smart", default is 16
            });
            doc.text(170, 40, 'Container Type', 'center');
            doc.autoTable(columns, rowData, {
                margin: {top: 60},
                startY: false,
                theme: 'grid',
                tableWidth: 'auto',
                columnWidth: 50,
                showHeader: 'everyPage',
                tableLineColor: 200,
                tableLineWidth: 0,
                headerStyles: {
                    theme: 'grid'
                },
                styles: {
                    overflow: 'linebreak',
                    columnWidth: 'wrap',
                    font: 'arial',
                    fontSize: 8,
                    cellPadding: 2,
                    overflowColumns: 'linebreak'
                },
            });
            let fileName = 'container_type';
            let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
            today = today.replaceAll("/", "");
            let time = new Date().getTime();

            doc.save(`${fileName}_${today}_${time}.pdf`);
            this.$notify({
                group: 'container',
                text: 'Formato Generado!',
                type: "success"
            });
        }
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    function onBtnExport() {
        if(this.formatedItems.length !== 0){
            let data = this.formatedItems.map(item => Object.assign({}, {
                "Port": item.Port,
                "Units": item.Units ? item.Units : '',
                "Teus": item.Teus ? item.Teus : '',
                "TotalWeight": item.TotalWeight ? item.TotalWeight : '',
                "Reefer": item.Reefer ? item.Reefer : '',
                "Dry": item.Dry ? item.Dry : '',
                "Full": item.Full,
                "Empty": item.Empty ? item.Empty : '',
                "Cont40Ft": item.Cont40Ft ? item.Cont40Ft : '',
                "Cont40FtWeight": item.Cont40FtWeight ? item.Cont40FtWeight : '',
                "Cont20Ft": item.Cont20Ft ? item.Cont20Ft : '',
                "Cont20FtWeight": item.Cont20FtWeight ? item.Cont20FtWeight : '',
                "BbUnits": item.BbUnits ? item.BbUnits : '',
                "BbWeight": item.BbWeight ? item.BbWeight : '',
            }));
            GenerateExcel(data, `container_type`);
            this.$notify({
                group: 'container',
                text: 'Formato Generado!',
                type: "success"
            });
        }
    }

    async function getInfo () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;

        let PortSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
        };

        await this.$http.post("StowagePlanningPortsSummary", PortSumaryJson, { root: 'PortSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            
            /*this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                Port: listado.Port,
                Units: formatMilDecimal(parseFloat(listado.Units).toFixed(2)),
                Teus: formatMilDecimal(parseFloat(listado.Teus).toFixed(2)),
                TotalWeight: formatMilDecimal(parseFloat(listado.TotalWeight).toFixed(2)),
                Reefer: formatMilDecimal(parseFloat(listado.Reefer).toFixed(2)),
                Dry: formatMilDecimal(parseFloat(listado.Dry).toFixed(2)),
                Class: formatMilDecimal(parseFloat(listado.Class).toFixed(2)),
                Full: formatMilDecimal(parseFloat(listado.Full).toFixed(2)),
                Empty: formatMilDecimal(parseFloat(listado.Empty).toFixed(2)),
                Cont40Ft: formatMilDecimal(parseFloat(listado.Cont40Ft).toFixed(2)),
                Cont40FtWeight: formatMilDecimal(parseFloat(listado.Cont40FtWeight).toFixed(2)),
                Cont20Ft: formatMilDecimal(parseFloat(listado.Cont20Ft).toFixed(2)),
                Cont20FtWeight: formatMilDecimal(parseFloat(listado.Cont20FtWeight).toFixed(2)),
                BbUnits: formatMilDecimal(parseFloat(listado.BbUnits).toFixed(2)),
                BbWeight: formatMilDecimal(parseFloat(listado.BbWeight).toFixed(2)),
            }));*/
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-info text-white font-weight-bold'
        }
    }

    export default {
        name: "port-summary",
        data,
        beforeMount,
        mounted,
        mixins: [AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getInfo,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
        },
        computed:{
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            }),
        },
        components:{
            
        },
        watch:{
            value: function () {
                if (this.value==19) {
                    this.getInfo();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        },
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>